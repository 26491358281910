<template>
  <div>
    <v-card class="ecosystem-authorizations">
      <v-card-title>
        <span>{{$t('t.EcosystemAuthorizations')}}</span>
        <v-btn
          class="ml-4"
          :disabled="!isDirty"
          fab
          x-small
          color="success"
          @click.stop="showConfirmation = true"
        >
          <v-icon ref="check">{{$icon('i.Checked')}}</v-icon>
        </v-btn>
        <v-btn
          class="ml-2"
          :disabled="!isDirty"
          fab
          x-small
          color="warning"
          @click.stop="load()"
        >
          <v-icon>{{$icon('i.Undo')}}</v-icon>
        </v-btn>
      </v-card-title>
      <v-skeleton-loader
        type="image"
        class="risk"
        v-if="!settings"
      />
      <v-card-text v-else>

        <v-form>
          <ul class="ecs-res-grid">
            <v-switch
              dense
              :label="$t('t.CollaborationTask')"
              v-model="settings.collaborationEngine"
            />
            <v-switch
              dense
              :label="$t('t.CreditInsurance')"
              v-model="settings.creditInsuranceEngine"
            />

            <v-switch
              dense
              :label="$t('t.Dispute')"
              v-model="settings.disputeEngine"
            />
            <!-- <v-switch
              dense
              :label="$t('t.DunningSender')"
              v-model="settings.dunningSenderEngine"
            /> -->
            <!-- <v-switch
              dense
              :label="$t('t.EscalationProtocols')"
              v-model="settings.escalationEngine"
            /> -->
            <v-switch
              dense
              :label="$t('t.Promise')"
              v-model="settings.promiseEngine"
            />
            <v-switch
              dense
              :label="$t('t.Risk')"
              v-model="settings.riskEngine"
            />
            <v-switch
              dense
              :label="$t('t.WorkItems')"
              v-model="settings.workItemEngine"
            />
            <!-- <grid> -->
            <div>
              <v-switch
                dense
                :label="$t('t.IncomingEmail')"
                v-model="settings.receiveEmail"
                @change="receiveEmailChange"
              />
              <v-switch
                dense
                :disabled="!settings.receiveEmail"
                :label="$t('t.DeleteEmail')"
                v-model="settings.deleteEmail"
              />
            </div>
            <div>
              <v-switch
                dense
                :label="$t('t.OutgoingEmail')"
                v-model="settings.sendEmail"
              />
              <v-text-field
                :label="$t('t.EmailOverride')"
                v-model="settings.sendEmailOverride"
                :disabled="!settings.sendEmail"
                clearable
              />
            </div>
            <!-- </grid> -->
            <div>
              <v-switch
                dense
                :label="$t('t.SendSms')"
                v-model="settings.sendSms"
              />
              <v-text-field
                :label="$t('t.SmsOverride')"
                v-model="settings.sendSmsPhoneOverride"
                :disabled="!settings.sendSms"
                clearable
              />
            </div>
            <div>
              <v-switch
                dense
                :label="$t('t.LetterDematerialized')"
                v-model="settings.sendLetterDematerialized"
              />
            </div>
            <div>
              <v-switch
                dense
                :label="$t('t.MailevaLetter')"
                v-model="settings.sendMailevaLetter"
              />
            </div>
            <div>
              <v-switch
                dense
                :label="$t('t.AllocationDispute')"
                v-model="settings.allocationDisputeEngine"
              />
            </div>
          </ul>
        </v-form>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="showConfirmation"
      max-width=500
    >
      <v-card>
        <v-card-title>{{$t('t.Warning')}}</v-card-title>
        <v-card-text>
          <span>{{$t('t.EnterSecurityCode', {code: securityCodeGenerated})}}</span>
          <v-text-field
            class="mt-2"
            :label="$t('t.ConfirmSecurityCode')"
            v-model="securityCodeValue"
            clearable
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="securityCodeValue !== securityCodeGenerated"
            @click="save()"
          >{{$t('t.Ok')}}</v-btn>
          <v-btn @click="confirmationCancel()">{{$t('t.Cancel')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  comments: {
    // Grid: () => import('@/components/grid'),
  },
  mounted () {
    this.load()
  },
  computed: {
    isDirty () {
      return !(JSON.stringify(this.settings) === JSON.stringify(this.initialSettings))
    },
    securityCodeGenerated () {
      return this.moment().format('YYYYMMDD')
    }
  },
  data () {
    return {
      initialSettings: null,
      securityCodeValue: null,
      settings: null,
      showConfirmation: false
    }
  },
  methods: {
    receiveEmailChange () {
      this.settings.deleteEmail = false
    },
    async load () {
      this.showConfirmation = false
      this.securityCodeValue = null
      const r = await this.$http().get('/core/v6/settings/ecosystem-authorizations')
      this.settings = r?.data
      this.initialSettings = Object.assign({}, r?.data)
    },
    confirmationCancel () {
      this.securityCodeValue = null
      this.showConfirmation = false
    },
    async save () {
      await this.$http().post('/core/v6/settings/ecosystem-authorizations', this.settings)
        .then(() => {
          this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SaveConfirmation'))
          this.load()
        })
        .catch(e => this.$store.dispatch('showErrorSnackbar', e.response?.data?.message))
    }
  }
}
</script>

<style lang="stylus">
.ecs-res-grid
  display grid
  grid-template-columns repeat(auto-fill, minmax(15rem, auto))
  column-gap 1em
</style>
